jQuery(document).ready(function($) { 


  /* **************************************************  */
  /* SLIDER CAT HOME */
  /* **************************************************  */

  $slick_slider = $('.Gallery_JS_MOBILE');
  settings_slider = {
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows:false,
  dots: true,
  responsive: [
    {
      breakpoint: 450,
      settings: {
       slidesToShow: 2,
        slidesToScroll: 2,
        arrows:false,
        dots: true
      }
    }
  ]
    // more settings
  }

  slick_on_mobile( $slick_slider, settings_slider);

// slick on mobile
  function slick_on_mobile(slider, settings){
    $(window).on('load resize', function() {
      if ($(window).width() > 767) {
        if (slider.hasClass('slick-initialized')) {
          slider.slick('unslick');
        }
        return
      }
      if (!slider.hasClass('slick-initialized')) {
        return slider.slick(settings);
      }
    });
  };





/********************/
/**** TRIANGLES *******/
/********************/
   
  /*TRIANGLE HAUT DROITE VIOLET */

  var dessin_0 = $("#dessin_0");
  $('.ContainerTriangle1').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
       if (isInView) {
          dessin_0.addClass('to_anim');
      }else{
         dessin_0.removeClass('to_anim');
      }

  });


  var dessin_1 = $("#dessin_1");
  var dessin_vert_top = $("#dessin_vert_top");
  $('.ContainerTriangle2').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
       if (isInView) {
        dessin_1.addClass('to_anim');
        dessin_vert_top.addClass('to_anim');
      }else{
        dessin_1.removeClass('to_anim');
        dessin_vert_top.removeClass('to_anim');
      }

  });


  $('.ContainerEtoile, .ContainerTriangleVisite, #infos-pratique, #contact').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
       if (isInView) {
        $(this).addClass('to_anim');
      }else{
        $(this).removeClass('to_anim');
      }

  });

  var dessin_3 = $("#dessin_3");
  var dessin_triangle_rose = $("#dessin_triangle_rose");
  $('.ContainerTrianglebleu').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
       if (isInView) {
        dessin_3.addClass('to_anim');
        dessin_triangle_rose.addClass('to_anim');
      }else{
        dessin_3.removeClass('to_anim');
         dessin_triangle_rose.removeClass('to_anim');
      }

  });

  


  var LARGEUR_MAX_MOBILE = 1023;
  var h_header = $("#masthead").height();
  var header = $('#masthead');
  // var admin_bar = 0;
  // if ($("body").hasClass('admin-bar')) admin_bar = 32;
  var h_window = $(window).height();

   $(window).scroll(function () {
      action_on_scroll();
    });

    function action_on_scroll(){

      var l_window = parseInt($(window).width());
      h_header = $("#masthead").height();

      if (l_window > LARGEUR_MAX_MOBILE){
         if ($(window).scrollTop() > h_header - 30){
          header.addClass("Masthead--sticky");
              }else{
                header.removeClass("Masthead--sticky");
              }
      }else{

        if ($(window).scrollTop() > 0){
          header.addClass("fixed_scrol_mobile");
          }else{
            header.removeClass("fixed_scrol_mobile");
          }
      }
   }


   action_on_scroll();


    // Cache selectors
  var lastId,
      topMenu = $("#primary-menu"),
      Sitenavigation = $("#site-navigation"),
      Menutoggle = $('.menu-toggle');
      topMenuHeight = topMenu.outerHeight(),
      // All list items
      menuItems = topMenu.find("a"),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function(){
        var item = $($(this).attr("href"));
        if (item.length) { return item; }
      });

     var l_window = parseInt($(window).width());
    if(l_window < 1023){

        topMenuHeight = $('#masthead').outerHeight();

    }
  // Bind click handler to menu items
  // so we can get a fancy scroll animation
  menuItems.click(function(e){
    var href = $(this).attr("href");
    var variable = 0;
        if (href == "#realisations")  variable = 170;
        var offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1-variable;
        if(Sitenavigation.hasClass('toggled')){
          Menutoggle.trigger( "click" );
          $('html, body').stop().animate({ 
            scrollTop: offsetTop
        }, 500);

        }else{
             $('html, body').stop().animate({ 
            scrollTop: offsetTop
        }, 500);
        }
     
      e.preventDefault();
  });

  // var other_link =  $('.Intro-Link-js, .intro-link-js')
  // other_link.click(function(e){
  //   var href = $(this).attr("href"),
  //       offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
  //     $('html, body').stop().animate({ 
  //         scrollTop: offsetTop
  //     }, 300);
  //     e.preventDefault();
  // });

  // Bind to scroll
  $(window).scroll(function(){
     // Get container scroll position
     var fromTop = $(this).scrollTop()+topMenuHeight;
     
     // Get id of current scroll item
     var cur = scrollItems.map(function(){
       //if($(this).attr('id') == "realisations") variable_top = 170;else variable_top = 0;
       variable_top = 0;
       if ($(this).offset().top - variable_top < fromTop)
         return this;
     });
     // Get the id of the current element
     cur = cur[cur.length-1];
     var id = cur && cur.length ? cur[0].id : "";
     
     if (lastId !== id) {
         lastId = id;
         // Set/remove active class
         menuItems
           .parent().removeClass("active_menu")
           .end().filter("[href='#"+id+"']").parent().addClass("active_menu");
     }                   
  });
  

  



  /* *****************  */
    /*  AJAX - RECAPTCHA FORM INSCRIPTION / PAGE PATRIMOINE */
  /* *****************  */

  //this submit part need to be move to the recaptacha validation script after succeed.
  var ToDisplayMessage_inscription = $('#message_form_inscription');
  ToDisplayMessage_inscription.hide();
  var IdFormMessage =  $('#form_inscription_token');

  $('.FormInscription').submit(function(e) {
    e.preventDefault();
    
    var This_new_form = $(this);

    This_new_form.addClass('form__to_load');

    var action_this_form = 'form_inscription';

      grecaptcha.execute('6Le949cUAAAAAKwCJatkV5Sxw-7BMqf1vJXTws_J', {action: action_this_form}).then(function(token) {
      document.getElementById('form_inscription_token').value=token;
      var formData = This_new_form.serialize();
     
        // AJAX POUR VERIFIER LE CAPTCHA //
         $.ajax({
              url : 'form_message.php',
              method : 'POST',
              data : {
                'action' : 'inscription_ajax_form',
                form_data : formData,
            }
          }).done(function(response) {
           
            $('#nom_inscription, #prenom_inscription, #email_inscription, #message_inscription').val("");
            This_new_form.removeClass('form__to_load')
            ToDisplayMessage_inscription.removeClass('error').addClass('success');
            ToDisplayMessage_inscription.slideDown(300);
            ToDisplayMessage_inscription.html('<p><strong>Votre demande a bien été enregistrée</strong></p><p>'+response+'</p>');


        }).fail(function(data) {
         /// ICI, PAS LE BON CAPTCHA OU PROBLEME DE FORMULAIRE
          This_new_form.removeClass('form__to_load')
        // console.log('PB DE FORMULAIRE');
        // console.log(data.responseText);
         ToDisplayMessage_inscription.removeClass('success').addClass('error');
         ToDisplayMessage_inscription.slideDown(300);
         ToDisplayMessage_inscription.html('<p><strong>Erreur de validation</strong></p><p>'+data.responseText+'</p>');


      });

    }, function (reason) {
      // ICI LE CAPTCHA QUI VEUT PAS S'EXECUTER
        This_new_form.removeClass('form__to_load')
        ToDisplayMessage_inscription.removeClass('success').addClass('error');
        ToDisplayMessage_inscription.slideDown(300);
        ToDisplayMessage_inscription.html('<p><strong>Erreur de validation</strong></p><p>'+reason+'</p>');
        console.log(reason);

   });


    });


     //  WOW EFFECT //
    var wow = new WOW(
      {
        boxClass:     'wow',      // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset:       0,          // distance to the element when triggering the animation (default is 0)
        mobile:       false,       // trigger animations on mobile devices (default is true)
        live:         true,       // act on asynchronously loaded content (default is true)
        callback:     function(box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null // optional scroll container selector, otherwise use window
      }
    );
   wow.init();
   
   


}); // fin ready menu


